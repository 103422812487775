import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ADSPageLoading } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

const DEFAULT_AUTHENTICATION_PATH = '/start';

export type PrivateRouteProps = {
  authenticationPath?: string;
} & RouteProps;

export default function PrivateRoute({
  authenticationPath = DEFAULT_AUTHENTICATION_PATH,
  ...routeProps
}: PrivateRouteProps) {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user?.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status, data } = useFirestoreDocData<IUserProfile>(userProfileRef);
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/start',
          state: { from: routeProps.location },
        }}
      />
    );
  }
  if (status === 'loading' || !data) {
    return <ADSPageLoading />;
  }
  const { firstName, lastName } = data;
  if (!firstName && !lastName) {
    return (
      <Redirect
        to={{
          pathname: '/onboarding',
          state: { from: routeProps.location },
        }}
      />
    );
  }
  return <Route {...routeProps} />;
}
