import { getAnalytics, setUserId } from 'firebase/analytics';
import {
  User,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';

import { ADSPageLoading } from '@aphrodite/common-ui';
import { firebaseFunctions } from '@aphrodite/firebase/firebase';
import { firebaseAuth } from '@aphrodite/firebase/firebase';

import createStrictContext from './createStrictContext';

interface IAuthContext {
  clearUser: () => void;
  user: User | null | undefined;
  isAuthenticating: boolean;
  sendSignInLinkToEmail: (college: string, email: string) => Promise<boolean>;
  sendSignInOTPToEmail: (college: string, email: string) => Promise<boolean>;
  signInWithEmailLink: (code: string) => Promise<boolean>;
  verifySignInOTP: (code: string) => Promise<boolean>;
}

const EMAIL_LOCAL_STORAGE = 'emailForSignIn';
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const [AuthContextProvider, useAuthHelper] = createStrictContext<IAuthContext>();
export { useAuthHelper };

/**
 * Wrapper hook for all firebase authentication related matters. Provider hook that creates auth object and handles state.
 *
 * By default, the user session will persist across sessions until explicit logout.
 * See https://firebase.google.com/docs/auth/web/auth-state-persistence.
 */
type Props = {
  children?: React.ReactNode;
};
export const AuthHelperProvider: React.FC<Props> = ({ children }) => {
  const analytics = getAnalytics();
  const [user, setUser] = useState<User | null>();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const sendSignInOTPToEmail = async (college: string, email: string): Promise<boolean> => {
    return httpsCallable(
      firebaseFunctions,
      'sendSignInOTPToEmail',
    )({ college, email }).then((_) => {
      window.localStorage.setItem(EMAIL_LOCAL_STORAGE, email);
      return true;
    });
  };
  const verifySignInOTP = async (otp: string): Promise<boolean> => {
    let email = window.localStorage.getItem(EMAIL_LOCAL_STORAGE);
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    const result = await httpsCallable(firebaseFunctions, 'verifySignInOTP')({ email, otp });
    window.localStorage.removeItem(EMAIL_LOCAL_STORAGE);
    const customToken = (result.data as { token: string }).token;
    const userCredential = await signInWithCustomToken(firebaseAuth, customToken);
    setUserId(analytics, userCredential.user.uid);
    setUser(userCredential.user);
    return true;
  };

  const sendSignInLinkToEmail = async (college: string, email: string): Promise<boolean> => {
    return httpsCallable(
      firebaseFunctions,
      'sendSignInLinkToEmail',
    )({
      college,
      email,
      origin: window.location.origin,
    }).then((_) => {
      window.localStorage.setItem(EMAIL_LOCAL_STORAGE, email);
      return true;
    });
  };
  const signInWithEmailLink = async (code: string): Promise<boolean> => {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem(EMAIL_LOCAL_STORAGE);
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // Client SDK will parse code from the link for us.
    return firebaseSignInWithEmailLink(firebaseAuth, email ? email : '', window.location.href).then(
      (result) => {
        // Clean up email from storage.
        window.localStorage.removeItem(EMAIL_LOCAL_STORAGE);
        setUser(result.user);
        setUserId(analytics, result.user!.uid);
        return true;
      },
    );
  };
  const clearUser = () => {
    setUser(null);
  };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      console.log('onauthchange: ', user);
      setUser(user);
      setIsAuthenticating(false);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [firebaseAuth]);
  const values = {
    user,
    clearUser,
    isAuthenticating,
    sendSignInLinkToEmail,
    sendSignInOTPToEmail,
    signInWithEmailLink,
    verifySignInOTP,
  };
  if (isAuthenticating) {
    return <ADSPageLoading />;
  }
  return <AuthContextProvider value={values}>{!isAuthenticating && children}</AuthContextProvider>;
};
