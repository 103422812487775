import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { ADSAnchor, ADSButton, ADSCard, ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';
import { SectionData } from '@aphrodite/common/types/DataReportTypes';
import { getAnalytics, logEvent } from '@firebase/analytics';

import { renderSection } from '../../pages/PageReport';
import Banner from '../Banner';

// Styled Components
const StyledArticleContainerCard = styled(ADSCard)`
  align-items: center;
  border-radius: 24px;
  display: flex;
  flex-direction: column nowrap;
  margin: 68px auto;
  max-width: 720px;
  @media only screen and (min-width: 1024px) {
    padding: 0 20px;
  }
`;
const StyledArticlePage = styled.div`
  padding: 12px 20px;
  width: 100%;
  max-width: 720px;
`;
const StyledArticleDate = styled(ADSText)`
  margin: 12px 0;
`;
const StyledArticleParagraph = styled(ADSText)`
  margin-top: 20px;
  @media only screen and (min-width: 600px) {
    margin-top: 36px;
  }
`;
const StyledButtonRegion = styled.div`
  margin: 28px 0;
  display: flex;
  justify-content: center;
`;

interface Props {
  sections: SectionData[];
  date: string | undefined;
  numStudents: number | undefined;
  university: string | undefined;
  year: string | undefined;
}

const Report2021: FunctionComponent<Props> = ({
  sections,
  date,
  numStudents,
  university,
  year,
}) => {
  const analytics = getAnalytics();
  return (
    <ADSPage>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StyledArticleContainerCard backgroundColor="translucent">
        <StyledArticlePage>
          <Banner>{`${university} ${year} Aphrodite Project Data Report`}</Banner>
          <StyledArticleDate color="standardGrey80" size="m">
            {date}
          </StyledArticleDate>
          <StyledArticleParagraph hyphensNone={true}>
            {`We've spent the past few months hard at work, crunching the ${year} data from ${numStudents} confirmed ${university} student participants.`}
            <br />
            <br />
            {`We create this aggregated data 
          report to give value back to you, the student community that made Aphrodite possible.`}
          </StyledArticleParagraph>
          <StyledArticleParagraph hyphensNone={true}>
            <b>In this report, we strived to maintain anonymity and sensitivity to participants:</b>
            <br />
            <br />
            {
              '• We omitted all dealbreaker quesitons styled as "My match SHOULD NOT be..." to avoid perpetuating any harmful stereotypes about the social desirability of any sexual orientation, ethnicity, religion, or diet.'
            }
            <br />
            <br />
            {'• We excluded categories with under 25 people to maintain participant anonymity.'}
          </StyledArticleParagraph>
          <br />
          <ADSHeading level="3" weight="bold">
            – Preamble –
          </ADSHeading>
          <StyledArticleParagraph>
            This report was born from our belief that we have a responsiblity to reciprocate
            participants like you with meaningful insights from the data you've entrusted to us.
            <br />
            <br />
            We hope that this report can spur interesting discourse and ideas, and help us better
            embrace the similarities, differences, and unique diversity of our student community. We
            hope that someone may find comfort in knowing that they're not alone in some aspects of
            their life.
          </StyledArticleParagraph>
          <br />
          <br />
          {sections?.map((section, index) => {
            return <React.Fragment key={index}>{renderSection(section)}</React.Fragment>;
          })}
          <ADSText blockquote={true} hyphensNone={true}>
            Before you go, to the {year} Aphrodite Project participants, we want to thank you for
            participating in the Aphrodite Project! We know that no matches are perfect, but we hope
            we've helped you meet a new friend or maybe even someone special.
            <br />
            <br />
            We know that the data we collect can be intimate. To that end, we do not sell your data
            nor do we intend to. However, so much of what we do, the tools, secure platforms we rely
            on costs plenty of dough, effort, and time! This report, created for you and the student
            community also took us months of effort to create.
            <br />
            <br />
            Please consider supporting us in our mission to build a more loving, inclusive world and
            create opportunities for intimate and meaningful relationships by buying us a coffee!
          </ADSText>
          <StyledButtonRegion>
            <ADSAnchor target="_blank" to="https://www.buymeacoffee.com/aphroditecupids">
              <ADSButton
                onClick={() =>
                  logEvent(analytics, 'link-click', {
                    component: 'DATA_REPORT',
                    button_name: 'donation',
                  })
                }
              >
                Buy us a coffee!
              </ADSButton>
            </ADSAnchor>
          </StyledButtonRegion>
        </StyledArticlePage>
      </StyledArticleContainerCard>
    </ADSPage>
  );
};

export default Report2021;
