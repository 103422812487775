import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import styled, { css, keyframes } from 'styled-components';

import { ADSHeading, ADSPage, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';

interface Props {
  college: string;
  email: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 1000px;
  max-width: 100%;
  padding: 0 8%;
  margin: auto;
  margin-top: 12%;
`;

// Create a shake animation similar to your SCSS @keyframes
const shakeAnimation = keyframes`
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
`;

// Styled OTP Input that mimics .otpInput and conditionally applies error border and shake animation.
interface StyledOTPInputProps {
  hasError?: boolean;
  shouldShake?: boolean;
}

const StyledOTPInput = styled.input<StyledOTPInputProps>`
  margin: 50px 0;
  font-size: 24px;
  height: 88px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#ccc')};
  background-color: transparent;
  text-align: center;

  ${({ shouldShake }) =>
    shouldShake &&
    css`
      animation: ${shakeAnimation} 0.3s ease-in-out;
    `}
`;

// A styled version of ADSText for the OTP resend message.
const OTPInfoText = styled(ADSText)<{ clickable?: boolean }>`
  margin: 2% 0;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
  /* Adjust the disabled color as needed (using a gray similar to $standardGrey80) */
  color: ${({ clickable }) => (clickable ? 'inherit' : '#999')};
`;

const OTPPage: FunctionComponent<Props> = ({ college, email }) => {
  const { sendSignInOTPToEmail, verifySignInOTP } = useAuthHelper();
  const [seconds, setSeconds] = useState(0);
  const [numFailedOTP, setNumFailedOtp] = useState(0);
  const [otpLoading, setOTPLoading] = useState(false);
  const [otp, setOTP] = useState('');

  const isResendOTPEnabled = useCallback(() => {
    return seconds === 0;
  }, [seconds]);

  const onChange = async (otp: string) => {
    setOTP(otp);
    if (otp.length === 5) {
      setOTPLoading(true);
      try {
        await verifySignInOTP(otp);
      } catch (error: any) {
        console.error('Error verifying OTP: ', error);
        setNumFailedOtp((prev) => prev + 1);
        // // Handle errors based on error code
        // // The error object from httpsCallable typically has a "code" and "message"
        // if (error.code === 'invalid-argument') {
        //   // For an incorrect OTP
        //   setNumFailedOtp((prev) => prev + 1);
        // } else if (error.code === 'deadline-exceeded') {
        //   alert('OTP has expired. Please go back and request a new one.');
        // } else if (error.code === 'not-found') {
        //   alert('OTP not found, you need to go back and request a new code.');
        // } else {
        //   alert(error.message || 'An unknown error occured. Please contact our team.');
        // }
      } finally {
        setOTPLoading(false);
      }
    }
  };
  const resendCode = () => {
    sendSignInOTPToEmail(college, email);
  };

  useEffect(() => {
    // On Component mount, set seconds to 60.
    setSeconds(60);
  }, []);
  useEffect(() => {
    if (isResendOTPEnabled()) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [seconds, isResendOTPEnabled]);
  return (
    <ADSPage>
      <StyledContainer>
        <ADSHeading level="1">let's confirm your email first!</ADSHeading>
        <ADSText>{'Last step, promise!'}</ADSText>
        <ADSText>
          {'Please enter the code sent to '}
          <u>{email.toLowerCase()}</u>. If you can't find it, check your junk mailbox!
        </ADSText>
        <OtpInput
          value={otp}
          skipDefaultStyles={true}
          onChange={onChange}
          numInputs={5}
          // For a separator between inputs – here a 20px spacer.
          renderSeparator={() => <div style={{ width: 20 }} />}
          // The custom input component, using our styled component.
          renderInput={(inputProps, index) => (
            <StyledOTPInput
              {...inputProps}
              // Disable input if loading or if user has hit 3 failed attempts.
              disabled={otpLoading || numFailedOTP === 3}
              // If the OTP has failed at least once but less than 3 times, show an error style and shake.
              hasError={numFailedOTP > 0 && numFailedOTP < 3}
              shouldShake={numFailedOTP > 0 && numFailedOTP < 3}
              key={index}
            />
          )}
          inputType="text"
        />
        {/* Show error text if OTP is invalid */}
        {numFailedOTP > 0 && (
          <ADSText size="s" color="aphroRedDark">
            {'Invalid code! Please try again'}
          </ADSText>
        )}
        {isResendOTPEnabled() ? (
          <OTPInfoText
            size="s"
            clickable
            inline
            weight="semibold"
            onClick={() => {
              setSeconds(60);
              resendCode();
            }}
          >
            {'Resend code'}
          </OTPInfoText>
        ) : (
          <ADSText size="s" inline weight="semibold">
            {'Code sent! You can resend the code again in ' + seconds + ' seconds'}
          </ADSText>
        )}
        {/* Show additional message after 3 failed attempts */}
        {numFailedOTP === 3 && (
          <ADSText size="s" color="aphroRedDark">
            {
              'Because you have entered an incorrect code three times, we will need you to resend a code'
            }
          </ADSText>
        )}
      </StyledContainer>
    </ADSPage>
  );
};

export default OTPPage;
