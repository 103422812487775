import React, { useEffect, useState } from 'react';
import { useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';

import { ADSHeading, ADSText } from '@aphrodite/common-ui';
import { useAuthHelper } from '@aphrodite/common/hooks';
import {
  GraphData,
  ReportData,
  ReportEnum,
  SectionData,
} from '@aphrodite/common/types/DataReportTypes';
import { IUserProfile } from '@aphrodite/common/types/firestore-types';
import { firebaseFirestore } from '@aphrodite/firebase/firebase';
import { DocumentReference, doc } from '@firebase/firestore';

import GENERAL from '../../constants/data-reports/all.json';
import HKU_DATA from '../../constants/data-reports/hku2024.json';
import MCMASTER_DATA from '../../constants/data-reports/mcmaster2024.json';
import HARVARD_DATA from '../../constants/data-reports/mitxharvard2024.json';
import MIT_DATA from '../../constants/data-reports/mitxharvard2024.json';
import NTUDATA from '../../constants/data-reports/ntu2023.json';
import NUSDATA from '../../constants/data-reports/nus2023.json';
import SMUDATA from '../../constants/data-reports/smu2023.json';
import UBC_DATA from '../../constants/data-reports/ubc2024.json';
import UCLA_DATA from '../../constants/data-reports/ucla2024.json';
import UOFT_DATA from '../../constants/data-reports/uoft2024.json';
import UWATERLOO_DATA from '../../constants/data-reports/uwaterloo2024.json';
import WESTERN_DATA from '../../constants/data-reports/western2024.json';
import Graph from '../Graph';
import DashboardLoading from '../dashboard/DashboardLoading';
import Report2021 from '../reports/Report2021';

// Styled Components
export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledBrand = styled.div`
  margin-top: 28px;
  width: 100%;
  max-width: 720px;
`;
export const StyledBrandBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
export const StyledLogo = styled.img`
  height: 1.5em;
  margin-right: 12px;
`;
export const StyledArticlePage = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 720px;
`;
export const StyledArticleDate = styled(ADSText)`
  margin: 12px 0;
`;
export const StyledArticleParagraph = styled(ADSText)`
  margin-top: 20px;
  @media only screen and (min-width: 600px) {
    margin-top: 36px;
  }
`;
export const StyledHeading = styled(ADSHeading)`
  margin-top: 32px;
  margin-bottom: 8px;
`;
export const StyledSessionTitle = styled(ADSHeading)`
  margin: 8px 0;
  @media only screen and (min-width: 600px) {
    margin-bottom: 20px;
  }
`;
export const StyledGraph = styled(Graph)`
  margin-top: 16px;
  @media only screen and (min-width: 600px) {
    margin-top: 20px;
  }
`;
export const StyledButtonRegion = styled.div`
  margin: 28px 0;
  display: flex;
  justify-content: center;
`;

/**
 * Article ADSPage component for the data reports.
 */
export function getReportData(pathname: string): ReportData | null {
  if (pathname.includes(ReportEnum.NUS)) {
    return NUSDATA;
  } else if (pathname.includes(ReportEnum.NTU)) {
    return NTUDATA;
  } else if (pathname.includes(ReportEnum.SMU)) {
    return SMUDATA;
  } else if (pathname.includes(ReportEnum.UBC_DATA)) {
    return UBC_DATA;
  } else if (pathname.includes(ReportEnum.UCLA_DATA)) {
    return UCLA_DATA;
  } else if (pathname.includes(ReportEnum.UOFT_DATA)) {
    return UOFT_DATA;
  } else if (pathname.includes(ReportEnum.UWATERLOO_DATA)) {
    return UWATERLOO_DATA;
  } else if (pathname.includes(ReportEnum.HKU_DATA)) {
    return HKU_DATA;
  } else if (pathname.includes(ReportEnum.MCMASTER_DATA)) {
    return MCMASTER_DATA;
  } else if (pathname.includes(ReportEnum.MIT_DATA)) {
    return MIT_DATA;
  } else if (pathname.includes(ReportEnum.HARVARD_DATA)) {
    return HARVARD_DATA;
  } else if (pathname.includes(ReportEnum.WESTERN_DATA)) {
    return WESTERN_DATA;
  }
  return GENERAL;
}
export const renderGraphs = (graphs: GraphData[]) => {
  return graphs ? graphs.map((graph) => <StyledGraph key={graph.title} {...graph} />) : null;
};

export const renderSection = (section: SectionData) => {
  const { header, subheader, sectionTitle, description, graphs } = section;
  return (
    <>
      {header ? (
        <StyledHeading level="2" weight="bold">
          {header}
        </StyledHeading>
      ) : null}
      {subheader ? (
        <ADSText hyphensNone={true} size="m">
          {subheader}
        </ADSText>
      ) : null}
      <br />
      {sectionTitle ? (
        <StyledSessionTitle level="2" weight="semibold">
          {sectionTitle}
        </StyledSessionTitle>
      ) : null}
      {description ? (
        <>
          <ADSText hyphensNone={true} size="l">
            {description}
          </ADSText>
          <br />
        </>
      ) : null}
      {graphs ? renderGraphs(graphs) : null}
    </>
  );
};

export default function DashboardReport(): React.ReactElement {
  const { user } = useAuthHelper();
  const userProfileRef = doc(
    firebaseFirestore,
    `UserProfiles/${user!.uid}`,
  ) as DocumentReference<IUserProfile>;
  const { status, data } = useFirestoreDocData<IUserProfile>(userProfileRef);
  const { college } = data;
  const reportData = getReportData(college);
  const date = reportData?.date;
  const numStudents = reportData?.numStudents;
  const sections = reportData?.reportContents;
  const university = reportData?.university;
  const year =
    date && date.length >= 4 ? (parseInt(date.substring(date.length - 4), 10) - 1).toString() : '';
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, [setRendered]);
  if (status === 'loading') {
    return <DashboardLoading />;
  }
  // The datareport takes awhile to render, so this lets us show a loading spinner while we wait

  if (!rendered || !sections) {
    return <DashboardLoading />;
  }
  return (
    <Report2021
      sections={sections}
      date={date}
      numStudents={numStudents}
      university={university}
      year={year}
    />
  );
}
