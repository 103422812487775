import { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';

import bigBg from '@aphrodite/assets/images/large-bg.jpg';
import smallBg from '@aphrodite/assets/images/small-bg.jpg';

interface Props {
  children: ReactNode;
  className?: string;
  fadeIn?: boolean;
}

const StyledContainer = styled.div`
  &:before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    background: url(${smallBg}) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  @media only screen and (min-width: 680px) {
    &:before {
      background: url(${bigBg}) no-repeat center center fixed;
      content: '';
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: -10;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    background: none;
  }
`;
const gradient = keyframes`
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 50%;
	}
  100% {
		background-position: 0% 0%;
	}
`;
const StyledLandingContainer = styled(StyledContainer)`
  &:before {
    background: none;
  }
  background: linear-gradient(55deg, #f6dfec, #fbebe2, #b1cdf0);
  background-size: 300% 150%;
  animation: ${gradient} 8s linear infinite;
  height: 120vh;
  background-attachment: scroll;
  @media only screen and (min-width: 680px) {
    &:before {
      background: none;
    }
  }
`;
const FadeInKeyframe = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const FadeInMixin = css`
  animation: ${FadeInKeyframe} ease 1.5s;
  -webkit-animation: ${FadeInKeyframe} ease 1.5s;
  -moz-animation: ${FadeInKeyframe} ease 1.5s;
  -o-animation: ${FadeInKeyframe} ease 1.5s;
  -ms-animation: ${FadeInKeyframe} ease 1.5s;
`;
const FadeInContainer = styled.div<{ fadeIn: boolean }>`
  ${(props) => (props.fadeIn ? FadeInMixin : undefined)};
`;
export default function ADSPage({
  className,
  children,
  fadeIn = false,
}: Props): React.ReactElement {
  if (className === 'Landing') {
    return (
      <StyledLandingContainer className={className}>
        <FadeInContainer fadeIn={fadeIn}>{children}</FadeInContainer>
      </StyledLandingContainer>
    );
  } else
    return (
      <StyledContainer className={className}>
        <FadeInContainer fadeIn={fadeIn}>{children}</FadeInContainer>
      </StyledContainer>
    );
}
